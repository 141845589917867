const frontDisplayUtils = require('../front/utils/displayUtils');

module.exports.init = function(hyperform) {
    for (let i = 1; i < 4; i++) {
        hyperform.addValidator(
            document.getElementById('contact_other_' + i + '_name'),
            function (element) {
                // Decision Maker is Receipt Recipient
                const dmIsRr = document.getElementById('contact').checked;

                let message = '';
                if(!dmIsRr && frontDisplayUtils.isVisible(document.getElementById('contact_' + i))) {
                    if(!element.value) {
                        message = element.getAttribute('data-value-missing');
                    }
                }

                element.setCustomValidity(message);

                return !message;
            }
        );

        hyperform.addValidator(
            document.getElementById('contact_other_' + i + '_forname'),
            function (element) {
                // Decision Maker is Receipt Recipient
                const dmIsRr = document.getElementById('contact').checked;

                let message = '';
                if(!dmIsRr && frontDisplayUtils.isVisible(document.getElementById('contact_' + i))) {
                    if(!element.value) {
                        message = element.getAttribute('data-value-missing');
                    }
                }

                element.setCustomValidity(message);

                return !message;
            }
        );

        hyperform.addValidator(
            document.getElementById('contact_other_' + i + '_function'),
            function (element) {
                // Decision Maker is Receipt Recipient
                const dmIsRr = document.getElementById('contact').checked;

                let message = '';
                if(!dmIsRr && frontDisplayUtils.isVisible(document.getElementById('contact_' + i))) {
                    if(!element.value) {
                        message = element.getAttribute('data-value-missing');
                    }
                }

                element.setCustomValidity(message);

                return !message;
            }
        );

        hyperform.addValidator(
            document.getElementById('contact_other_' + i + '_phone'),
            function (element) {
                // Decision Maker is Receipt Recipient
                const dmIsRr = document.getElementById('contact').checked;

                let message = '';
                if(!dmIsRr && frontDisplayUtils.isVisible(document.getElementById('contact_' + i))) {
                    if(element.validity.typeMismatch) {
                        message = element.getAttribute('data-pattern-mismatch');
                    }
                }

                element.setCustomValidity(message);

                return !message;
            }
        );

        hyperform.addValidator(
            document.getElementById('contact_other_' + i + '_email'),
            function (element) {
                // Decision Maker is Receipt Recipient
                const dmIsRr = document.getElementById('contact').checked;

                let message = '';
                if(!dmIsRr && frontDisplayUtils.isVisible(document.getElementById('contact_' + i))) {
                    if(!element.value) {
                        message = element.getAttribute('data-value-missing');
                    } else if(element.validity.typeMismatch) {
                        message = element.getAttribute('data-pattern-mismatch');
                    }
                }

                element.setCustomValidity(message);

                return !message;
            }
        );

        hyperform.addValidator(
            document.getElementById('contact_other_' + i + '_madame'),
            function (element) {
                // Decision Maker is Receipt Recipient
                const dmIsRr = document.getElementById('contact').checked;

                let message = '';
                if(!dmIsRr && frontDisplayUtils.isVisible(document.getElementById('contact_' + i))) {
                    if(!element.checked && !document.getElementById('contact_other_' + i + '_monsieur').checked) {
                        message = element.getAttribute('data-value-missing');
                    } else if(element.validity.typeMismatch) {
                        message = element.getAttribute('data-pattern-mismatch');
                    }
                }

                element.setCustomValidity(message);

                return !message;
            }
        );
    }

    hyperform.addValidator(
        document.getElementById('student-class'), element => {
            if(!document.getElementById('former-student').checked) {
                element.setCustomValidity('');
                return true;
            }

            if(!element.value) {
                element.setCustomValidity('La promotion doit être saisie.');

                return false;
            }

            element.setCustomValidity('');

            return true;
        }
    );

    hyperform.addValidator(
        document.getElementById('student-name'), element => {
            if(!document.getElementById('student-parent').checked) {
                element.setCustomValidity('');
                return true;
            }

            if(!element.value) {
                element.setCustomValidity('Le nom du parent doit être saisi.');

                return false;
            }

            element.setCustomValidity('');

            return true;
        }
    );

    hyperform.addValidator(
        document.getElementById('provider-name'), element => {
            if(!document.getElementById('provider').checked) {
                element.setCustomValidity('');
                return true;
            }

            if(!element.value) {
                element.setCustomValidity('Le nom du fournisseur, prestataire, etc. doit être saisi.');

                return false;
            }

            element.setCustomValidity('');

            return true;
        }
    );
};